import api from '@/api/api'

export default {
  state: {
    user: null,
  },

  getters: {
    loggedIn(state) {
      return !!state.user
    },
  },

  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
    },

    CLEAR_USER_TOKEN() {
      localStorage.removeItem('access_token')
      location.href = '/login'
    },
  },

  actions: {
    login(_, credLogin) {
      return api({ requiresAuth: false }).post('/auth/login', credLogin)
    },
    async signup(_, { token, ...credentials }) {
      if (!token) {
        return api({ requiresAuth: false }).post(`/auth/register/`, credentials)
      } else
        return api({ requiresAuth: false }).post(
          `/auth/register/${token}`,
          credentials
        )
    },
    logout({ commit }) {
      commit('CLEAR_USER_TOKEN')
    },
    async forgotPassword(_, credentials) {
      return api({ requiresAuth: false }).post(
        '/auth/forgetPassword',
        credentials
      )
    },
    async changePassword(_, credentials) {
      return api({ requiresAuth: true }).patch(
        '/users/change-password',
        credentials
      )
    },
    async inviteParent(_, credentials) {
      return api({ requiresAuth: true }).post('/users/invite', credentials)
    },
  },
}
