<template>
  <div class="day">
    <div class="day__dates">
      <p
        class="day__message"
        v-if="
          this.getDaySchool.length === 0 && this.getDayActivity.length === 0
        "
      >
        no schedule for this day
      </p>
      <div
        class="day__date day__date--school"
        v-if="this.getDaySchool.length !== 0"
      >
        <p>
          <span>From: </span>
          {{ getDaySchool[0]?.from | luxon }}
        </p>
        <p><span>To: </span>{{ getDaySchool[0]?.to | luxon }}</p>
      </div>
      <div
        class="day__date day__date--activity"
        v-if="this.getDayActivity.length !== 0"
      >
        <p><span>From: </span>{{ getDayActivity[0]?.from | luxon }}</p>
        <p><span>To: </span>{{ getDayActivity[0]?.to | luxon }}</p>
      </div>
    </div>
    <div class="day__data">
      <SubjectCard v-for="sub in getDaySchool" :key="sub.id" :subject="sub" />
      <ActivityCard
        v-for="act in getDayActivity"
        :key="act.id"
        :activity="act"
      />
    </div>
  </div>
</template>

<script>
import SubjectCard from '@/components/SubjectCard.vue'
import ActivityCard from '@/components/ActivityCard.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    SubjectCard,
    ActivityCard,
  },
  computed: {
    ...mapGetters({
      getDaySchool: 'daySchedule/getDaySchool',
      getDayActivity: 'daySchedule/getDayActivity',
    }),
  },
  watch: {
    $route(to) {
      this.$store.commit('daySchedule/SET_DAY_CHANGE', to.query.day)
    },
  },
}
</script>

<style lang="scss" scoped>
.day__dates {
  max-width: toRem(310);
  margin: 0 auto;
  font-size: toRem(12);
}
.day__message {
  text-align: center;
  text-transform: uppercase;
  font-size: toRem(15);
  font-weight: bold;
}
.day__date {
  display: flex;
  gap: toRem(10);
  justify-content: center;
  margin-bottom: toRem(15);
  border-radius: toRem(5);
  padding: toRem(5);
  span {
    font-weight: bold;
  }
  &--school {
    background: #f8f7eb;
  }
  &--activity {
    background: $light-blue-color;
  }
}
.day__data {
  display: grid;
  grid-template-columns: toRem(155) toRem(155);
  justify-content: center;
  gap: toRem(10);
}
</style>
