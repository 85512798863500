<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="day" v-touch:swipe="swipeHandler">
      <div class="day__title">
        <div class="day__image">
          <img
            :src="childPhoto ?? require('@/assets/images/profile-img.png')"
          />
        </div>
        <h3 class="day__name">
          {{ this.$route.query.name }} on<br /><span>{{
            this.$route.query.day
          }}</span>
        </h3>
      </div>
      <div class="day__data">
        <div class="dots-flow" v-if="isLoading"></div>
        <SubjectActivityCards />
        <div class="day__buttons">
          <router-link
            :to="{
              path: '/day-schedule',
              query: {
                name: this.$route.query.name,
                id: this.$route.query.id,
                day: this.$route.query.day,
              },
            }"
            class="btn day__buttons--add-class"
            >Add Class / Activity
            <font-awesome-icon icon="fa-solid fa-plus" class="activity-plus"
          /></router-link>
          <router-link
            :to="{
              path: '/week-schedule',
              query: { name: this.$route.query.name, id: this.$route.query.id },
            }"
            class="btn day__buttons--go-back"
            ><font-awesome-icon
              icon="fa-solid fa-arrow-left"
              class="arrow-icon-left"
            />
            Go back
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import SubjectActivityCards from '@/components/SubjectActivityCards.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      childPhoto: null,
      isLoading: true,
      childId: '',
      childName: '',
    }
  },
  mounted() {
    this.getDaySchedule({
      childId: this.$route.query.id,
      day: this.$route.query.day,
    })

    this.childId = this.$route.query.id
    this.childName = this.$route.query.name
  },
  async created() {
    this.isLoading = true
    await this.getMyChildren()
    this.getCurrentChildren()
    this.isLoading = false
  },
  computed: {
    ...mapGetters({
      myChildren: 'users/myChildren',
    }),
  },
  methods: {
    getCurrentChildren() {
      const childId = this.$route.query.id
      const found = this.myChildren.find((child) => child.id === childId)
      return (this.childPhoto = found.photo)
    },
    swipeHandler(direction) {
      if (direction === 'right') {
        let currentD = this.$route.query.day
        switch (currentD) {
          case 'Monday':
            this.$router.replace({
              query: {
                day: 'Sunday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Tuesday':
            this.$router.replace({
              query: {
                day: 'Monday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Wednesday':
            this.$router.replace({
              query: {
                day: 'Tuesday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Thursday':
            this.$router.replace({
              query: {
                day: 'Wednesday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Friday':
            this.$router.replace({
              query: {
                day: 'Thursday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Saturday':
            this.$router.replace({
              query: {
                day: 'Friday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Sunday':
            this.$router.replace({
              query: {
                day: 'Saturday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          default:
            break
        }
      }
      if (direction === 'left') {
        let currentD = this.$route.query.day
        switch (currentD) {
          case 'Monday':
            this.$router.replace({
              query: {
                day: 'Tuesday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Tuesday':
            this.$router.replace({
              query: {
                day: 'Wednesday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Wednesday':
            this.$router.replace({
              query: {
                day: 'Thursday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Thursday':
            this.$router.replace({
              query: {
                day: 'Friday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Friday':
            this.$router.replace({
              query: {
                day: 'Saturday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Saturday':
            this.$router.replace({
              query: {
                day: 'Sunday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          case 'Sunday':
            this.$router.replace({
              query: {
                day: 'Monday',
                id: this.childId,
                name: this.childName,
              },
            })
            break
          default:
            break
        }
      }
      this.getDaySchedule({
        day: this.$route.query.day,
        childId: this.childId,
      })
    },
    ...mapActions({
      getMyChildren: 'users/getMyChildren',
      getDaySchedule: 'daySchedule/getDaySchedule',
    }),
  },
  components: {
    HeaderComponent,
    SubjectActivityCards,
  },
}
</script>

<style lang="scss" scoped>
.day__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: toRem(10);
  margin-bottom: toRem(80);
}
.day__image {
  background: rgba(255, 255, 255, 0.21);
  box-shadow: 0 toRem(5) toRem(10) rgb(88, 88, 88);
  padding: toRem(7) toRem(8) 0 toRem(8);
  border-radius: 50%;
  img {
    width: toRem(80);
    height: toRem(80);
    border-radius: 100%;
    object-fit: cover;
  }
}
.day__name {
  span {
    color: $white-color;
    font-size: toRem(30);
  }
}
.day__buttons {
  display: flex;
  flex-direction: column;
  gap: toRem(20);
  padding-top: toRem(50);
  padding-bottom: toRem(50);
  &--add-class {
    padding: toRem(10) 0 toRem(10) toRem(30);
    background-color: $yellow-color;
    color: $black-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .activity-plus {
      margin-right: toRem(30);
      font-size: toRem(20);
    }
  }
  &--go-back {
    padding: toRem(10) 0 toRem(10) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: toRem(10);
  }
}
.dots-flow:before {
  animation: dots-flow 0.85s infinite ease;
  animation: dots-flow 0.85s infinite ease;
  border-radius: 100%;
  content: '';
  height: toRem(16);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: toRem(16);
}

@keyframes dots-flow {
  0%,
  100% {
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
  }
  35% {
    box-shadow: -26px 32px 0 4px $light-blue-color, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
    box-shadow: -26px 32px 0 4px $light-blue-color, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
  }
  50% {
    box-shadow: -26px 32px 0 0 #f8f7eb, 0px 32px 0 4px $light-blue-color,
      26px 32px 0 0 #f8f7eb;
    box-shadow: -26px 32px 0 0 #f8f7eb, 0px 32px 0 4px $light-blue-color,
      26px 32px 0 0 #f8f7eb;
  }
  65% {
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 4px $light-blue-color;
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 4px $light-blue-color;
  }
}

@keyframes dots-flow {
  0%,
  100% {
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
  }
  35% {
    box-shadow: -26px 32px 0 4px $light-blue-color, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
    box-shadow: -26px 32px 0 4px $light-blue-color, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 0 #f8f7eb;
  }
  50% {
    box-shadow: -26px 32px 0 0 #f8f7eb, 0px 32px 0 4px $light-blue-color,
      26px 32px 0 0 #f8f7eb;
    box-shadow: -26px 32px 0 0 #f8f7eb, 0px 32px 0 4px $light-blue-color,
      26px 32px 0 0 #f8f7eb;
  }
  65% {
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 4px $light-blue-color;
    box-shadow: -26px 32px 0px 0 #f8f7eb, 0px 32px 0 0 #f8f7eb,
      26px 32px 0 4px $light-blue-color;
  }
}
</style>
