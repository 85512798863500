<template>
  <popup-modal ref="popup">
    <h2 class="popup-title">{{ title }}</h2>
    <div class="time">
      <select name="time" id="time" v-model="reminderTime" class="popup-time">
        <option disabled value="">Set Time</option>
        <option v-for="time in foo" :key="time">{{ time }}</option>
        <option value="Don't Remind Me">Don't Remind Me</option>
      </select>
    </div>
    <div class="btns">
      <button class="btn btn-cancel" @click="_cancel">
        {{ cancelButton }}
      </button>
      <span class="ok-btn" @click="_confirm">{{ okButton }}</span>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue'

export default {
  data() {
    return {
      title: undefined,
      okButton: undefined,
      cancelButton: 'X',
      reminderTime: '',
      resolvePromise: undefined,
      rejectPromise: undefined,
      interval: 60,
      startDate: 60 * 20,
      endDate: 60 * 22,
      foo: null,
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm() {
      this.$emit('child-method', this.reminderTime)
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
    },
    generateHoursInterval(startHourInMinute, endHourInMinute, interval) {
      const times = []
      for (let i = 0; startHourInMinute < 24 * 60; i++) {
        if (startHourInMinute > endHourInMinute) break
        var hh = Math.floor(startHourInMinute / 60)
        var mm = startHourInMinute % 60
        times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2)
        startHourInMinute = startHourInMinute + interval
      }
      return times
    },
    setData() {
      this.foo = this.generateHoursInterval(
        this.startDate,
        this.endDate,
        this.interval
      )
    },
  },
  components: { PopupModal },
}
</script>

<style lang="scss" scoped>
.popup-title {
  font-size: toRem(15);
  text-align: center;
}
.popup-message {
  font-size: toRem(13);
}
.btns {
  display: flex;
  flex-direction: column;
  margin-top: toRem(10);
}
.btn-cancel {
  position: absolute;
  background: transparent;
  color: #000000;
  font-size: 0.9375rem;
  right: toRem(10);
  top: toRem(12);
  font-weight: bold;
}
.ok-btn {
  color: green;
  margin: 0 auto;
}
.time {
  display: flex;
  justify-content: center;
}
.popup-time {
  width: 100%;
  padding: toRem(10) 0 toRem(10) toRem(15);
  box-sizing: border-box;
  background: $secondary-color url(../assets/icons/arrow-down-green.svg)
    no-repeat right toRem(12) center;
  color: $primary-color;
  border: none;
  outline: none;
  appearance: none;
  border-radius: toRem(50);
  margin-top: toRem(20);
  font-weight: bold;
  font-size: toRem(11);
  &-down-arrow {
    position: absolute;
    top: toRem(12);
    right: toRem(17);
  }
}
</style>
