var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conainer"},[_c('router-link',{attrs:{"to":{
      path: _vm.getRoutePath,
      query: {
        name: this.$route.query.name,
        id: this.$route.query.id,
        day: _vm.day.day,
      },
    }}},[_c('button',{staticClass:"week__day",class:_vm.getDayClass},[_vm._v(" "+_vm._s(_vm.day.day)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }