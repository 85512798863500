<template>
  <div id="app">
    <router-view />

    <div class="curve second" v-if="showSecondCurve"></div>
    <div class="curve second" v-if="showThirdCurve"></div>
    <div class="curve fourth" v-if="showFourthCurve"></div>
  </div>
</template>
<script>
export default {
  name: 'App',
  computed: {
    showFirstCurve() {
      return this.$route.name === 'AddChild'
    },
    showSecondCurve() {
      return (
        this.$route.name === 'ChildPage' ||
        this.$route.name === 'AddParent' ||
        this.$route.name === 'EditChild'
      )
    },
    showThirdCurve() {
      return (
        this.$route.name === 'WeekSchedule' ||
        this.$route.name === 'WeekScheduleWithData'
      )
    },
    showFourthCurve() {
      return (
        this.$route.name === 'DaySchedule' ||
        this.$route.name === 'CurrentDay' ||
        this.$route.name === 'AddActivity'
      )
    },
  },
}
</script>
<style lang="scss">
body {
  font-family: $font-family;
}
input,
button {
  font-family: $font-family;
}

.curve {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: 0;
  background-color: $primary-color;
  width: 100%;
  clip-path: ellipse(150% 100% at 50% 0%);
}

.second {
  height: toRem(650);
}
.third {
  height: toRem(620);
}
.fourth {
  min-height: toRem(280);
  clip-path: ellipse(99% 100% at 50% 0%);
}
.curve-one {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100%;
  &--first {
    background: url('./assets/icons/inset-bg-1.svg') no-repeat top;
    background-size: 100%;
  }
}
</style>
