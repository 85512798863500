var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('HeaderComponent'),_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.swipeHandler),expression:"swipeHandler",arg:"swipe"}],staticClass:"day"},[_c('div',{staticClass:"day__title"},[_c('div',{staticClass:"day__image"},[_c('img',{attrs:{"src":_vm.childPhoto ?? require('@/assets/images/profile-img.png')}})]),_c('h3',{staticClass:"day__name"},[_vm._v(" "+_vm._s(this.$route.query.name)+" on"),_c('br'),_c('span',[_vm._v(_vm._s(this.$route.query.day))])])]),_c('div',{staticClass:"day__data"},[(_vm.isLoading)?_c('div',{staticClass:"dots-flow"}):_vm._e(),_c('SubjectActivityCards'),_c('div',{staticClass:"day__buttons"},[_c('router-link',{staticClass:"btn day__buttons--add-class",attrs:{"to":{
            path: '/day-schedule',
            query: {
              name: this.$route.query.name,
              id: this.$route.query.id,
              day: this.$route.query.day,
            },
          }}},[_vm._v("Add Class / Activity "),_c('font-awesome-icon',{staticClass:"activity-plus",attrs:{"icon":"fa-solid fa-plus"}})],1),_c('router-link',{staticClass:"btn day__buttons--go-back",attrs:{"to":{
            path: '/week-schedule',
            query: { name: this.$route.query.name, id: this.$route.query.id },
          }}},[_c('font-awesome-icon',{staticClass:"arrow-icon-left",attrs:{"icon":"fa-solid fa-arrow-left"}}),_vm._v(" Go back ")],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }