import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSplash from 'vue-splash'
import VueLuxon from 'vue-luxon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
library.add(
  faPlus,
  faCamera,
  faArrowLeft,
  faArrowRight,
  faAngleDown,
  faTrashCan,
  faCheck,
  faXmark,
  faPen,
  faBell,
  faExclamation,
  faCircleExclamation
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueSplash)
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.component('date-time', Datetime)
import './assets/scss/index.scss'

import Multiselect from 'vue-multiselect'
Vue.component('multi-select', Multiselect)
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.config.productionTip = false
import './assets/scss/index.scss'

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

Vue.use(VueLuxon, {
  templates: {},
  input: {
    zone: 'utc',
    format: 'iso',
  },
  output: {
    zone: 'local',
    format: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    locale: null,
    relative: {
      round: true,
      unit: null,
    },
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
