import api from '@/api/api'

export default {
  namespaced: true,
  state: {
    activitySchedule: [],
    schoolSchedule: [],
    editSchoolSchedule: [],
    editActivitySchedule: [],
    changedDay: '',
  },

  getters: {
    getDaySchool: (state) => state.schoolSchedule,
    getDayActivity: (state) => state.activitySchedule,
    getEditSchoolSchedule: (state) => state.editSchoolSchedule,
    getDayChange: (state) => state.changedDay,
  },

  mutations: {
    SET_ALL_SCHEDULE(state, allSchedule) {
      let school, activity
      ;[school, activity] = [
        allSchedule.schoolSchedule,
        allSchedule.activitySchdedule,
      ]
      state.activitySchedule = activity
      state.schoolSchedule = school
    },

    CLEAR_DAY_SCHEDULE(state) {
      state.schoolSchedule = []
      state.activitySchedule = []
    },

    SET_DELETE_SUBJECT(state, data) {
      let findSubject = state.schoolSchedule.findIndex(
        (subject) => subject.id === data
      )

      if (findSubject > -1) {
        state.schoolSchedule.splice(findSubject, 1)
      }
    },

    SET_DELETE_ACTIVITY(state, data) {
      let findActivity = state.activitySchedule.findIndex(
        (activity) => activity.id === data
      )

      if (findActivity > -1) {
        state.activitySchedule.splice(findActivity, 1)
      }
    },

    SET_CURRENT_DAY_SCHEDULE(state, data) {
      state.editSchoolSchedule.push(data.schoolSchedule)
      state.editActivitySchedule.push(data.activitySchdedule)
    },

    SET_DAY_CHANGE(state, data) {
      state.changedDay = data
    },
  },

  actions: {
    async getDaySchedule({ commit }, { childId, day }) {
      try {
        let data = await api({ requiresAuth: true }).get(
          `/child/getMyChildSchedule/${childId}`,
          {
            params: { day },
          }
        )
        commit('SET_ALL_SCHEDULE', data.data)
        commit('SET_CURRENT_DAY_SCHEDULE', data.data)
      } catch (err) {
        console.error(err)
      }
    },
  },
}
