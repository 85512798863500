import Vue from 'vue'
import Vuex from 'vuex'
import modals from './modules/modals'
import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import children from '@/store/modules/children'
import subjects from '@/store/modules/subjects'
import activities from '@/store/modules/activities'
import daySchedule from '@/store/modules/daySchedule'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    modals,
    auth,
    users,
    children,
    subjects,
    activities,
    daySchedule,
  },
})
