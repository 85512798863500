import api from '@/api/api'

export default {
  namespaced: true,
  state: {
    subjects: [],
    response: '',
  },
  getters: {
    getAllSubjects: (state) => state.subjects,
  },
  mutations: {
    SET_SUBJECTS: (state, data) => {
      state.subjects = data
    },
    SET_ERROR_MSG: (state, message) => {
      console.log(message)
      state.response = message
    },
  },
  actions: {
    async getSubjects({ commit }) {
      try {
        let { data } = await api({ requiresAuth: true }).get('/subject/all')
        commit('SET_SUBJECTS', data)
      } catch (err) {
        console.error(err)
      }
    },
    schoolSchedule(_, { childId, schoolData }) {
      api({ requiresAuth: true }).post(
        `/school-schedule/${childId}/create`,
        schoolData
      )
    },
  },
}
