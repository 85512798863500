<template>
  <div class="container">
    <div class="activity">
      <p class="activity__name">
        {{ activity.activity.name }}
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="x-icon"
          @click="deleteActivity(activity.id)"
        />
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  props: {
    activity: {
      type: Object,
    },
  },
  methods: {
    async deleteActivity(id) {
      try {
        await api({ requiresAuth: true })
          .delete(`/activity-schedule/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit('daySchedule/SET_DELETE_ACTIVITY', id)
            }
          })
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.activity {
  height: toRem(100);
  background-color: $light-blue-color;
  border-radius: toRem(5);
  box-shadow: 0 toRem(2) toRem(5) rgb(177, 177, 177);
  position: relative;
  &__name {
    padding: toRem(20) 0 0 toRem(20);
    font-weight: 500;
    font-size: toRem(18);
    color: $white-color;
  }
  .x-icon {
    position: absolute;
    top: toRem(10);
    right: toRem(10);
  }
}
</style>
