<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="container">
      <AddClassOrActivity />
    </div>
  </div>
</template>
<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import AddClassOrActivity from '@/components/AddClassOrActivity.vue'
export default {
  name: 'DaySchedule',
  data() {
    return {}
  },
  components: {
    HeaderComponent,
    AddClassOrActivity,
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
.activity-button {
  width: 80%;
  margin: 0 auto;
}
</style>
