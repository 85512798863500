import api from '@/api/api'

export default {
  namespaced: true,
  state: {
    activities: [],
  },
  getters: {
    getAllActivities: (state) => {
      return state.activities
    },
  },
  mutations: {
    SET_ACTIVITIES: (state, data) => {
      state.activities = [...state.activities, ...data]
    },
  },
  actions: {
    async getActivities({ commit }) {
      try {
        let { data } = await api({ requiresAuth: true }).get('/activity/all')
        commit('SET_ACTIVITIES', data)
      } catch (err) {
        console.error(err)
      }
    },
  },
}
