import axios from 'axios'

import store from '../store'

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL })

export default ({ requiresAuth }) => {
  if (requiresAuth) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      'access_token'
    )}`
  }

  axiosInstance.interceptors.response.use(
    (response) => response,

    (error) => {
      if (error.response.status === 401) {
        store.dispatch('logout')
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}
