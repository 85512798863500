<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="title">
      <h2 class="title__content"><span>Welcome</span> {{ userName }}</h2>
    </div>
    <div class="container">
      <div class="add-child">
        <BaseButton />
        <p class="add-child__subtitle">Add child</p>
        <div class="add-child__parent">
          <router-link to="/add-parent" class="btn btn--add-parent">
            Add Parent<font-awesome-icon
              icon="fa-solid fa-plus"
              class="parent-plus"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import BaseButton from '@/components/BaseButton.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddChild',
  data() {
    return {
      userName: '',
    }
  },
  components: {
    HeaderComponent,
    BaseButton,
  },
  async mounted() {
    await this.getCurrentUser()
    this.userName = this.currentUser.username
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'users/getCurrentUser',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_buttons.scss';
.wrapper {
  background-color: $primary-color;
}
.container {
  background-color: $white-color;
  clip-path: ellipse(170% 100% at 50% 100%);
  height: auto;
}
.title__content {
  text-align: center;
  padding-top: toRem(30);
  padding-bottom: toRem(60);
  span {
    font-weight: 400;
  }
}
.add-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: toRem(60);
  &__subtitle {
    padding-top: toRem(30);
    color: $secondary-color;
    font-size: toRem(20);
    font-family: sans-serif;
  }
  &__parent {
    margin-top: toRem(100);
    .btn--add-parent {
      background: transparent;
      border: toRem(1) solid $secondary-color;
      color: $secondary-color;
      padding: toRem(10) toRem(70);
      font-size: toRem(20);
      display: flex;
      align-items: center;
      margin-bottom: toRem(30);
      .parent-plus {
        margin-left: toRem(15);
        border: toRem(2) solid $secondary-color;
        border-radius: 100%;
        padding: toRem(2);
      }
    }
  }
}
</style>
