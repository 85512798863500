<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible">
      <div class="window">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PopupModal',

  data: () => ({
    isVisible: false,
  }),

  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.window {
  width: 100%;
  background: #fff;
  border-radius: toRem(5);
  box-shadow: toRem(2) toRem(4) toRem(8) rgba(0, 0, 0, 0.2);
  padding: toRem(16);
  margin: 0 toRem(20);
  position: relative;
}
</style>
