import api from '@/api/api'
export default {
  namespaced: true,
  state: {
    currentUser: {},
    myChildren: [],
  },
  getters: {
    currentUser: (state) => state.currentUser,
    myChildren: (state) => state.myChildren,
  },
  mutations: {
    SET_CURRENT_USER: (state, data) => {
      state.currentUser = data
    },
    SET_CHILDREN: (state, data) => {
      state.myChildren = data
    },
    SET_DELETE_CHILD: (state, id) => {
      const childIndex = state.myChildren.findIndex((child) => child.id === id)
      if (childIndex !== -1) {
        state.myChildren.splice(childIndex, 1)
      }
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      try {
        let { data } = await api({ requiresAuth: true }).get('/users/me')
        commit('SET_CURRENT_USER', data)
      } catch (err) {
        console.error("Something went wrong.Can't get users " + err)
      }
    },
    async getMyChildren({ commit }) {
      try {
        const { data } = await api({ requiresAuth: true }).get(
          '/users/getMyChildren'
        )
        commit('SET_CHILDREN', data)
      } catch (err) {
        console.error('No children')
      }
    },
    createUser({ commit }, user) {
      commit('CREATE_USER', user)
    },
  },
}
