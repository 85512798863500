<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="container">
      <div class="week">
        <div class="week__title">
          <div class="week__image">
            <img
              :src="childPhoto ?? require('@/assets/images/profile-img.png')"
            />
          </div>
          <h3 class="week__name">
            Week Schedule<span>{{ this.$route.query.name }}</span>
          </h3>
        </div>
        <div class="dots-flow" v-if="isLoading"></div>
        <div v-else class="week__days">
          <day-component
            v-for="(day, index) in getDays"
            :key="index + 1"
            :day="day"
            :class="index === 6 && last"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import DayComponent from '@/components/DayComponent.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WeekSchedule',
  data() {
    return {
      childPhoto: null,
      weekDays: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
      last: 'last-item',
      isLoading: true,
    }
  },
  async created() {
    this.isLoading = true
    await this.getMyChildren()
    await this.getChildrenWeekDays(this.$route.query.id)
    this.getCurrentChildren()
    this.isLoading = false
  },
  computed: {
    ...mapGetters({
      myChildren: 'users/myChildren',
      storeWeekDays: 'children/weekDays',
    }),
    getGroupedDays() {
      return Object.keys(this.storeWeekDays).length
        ? [
            ...this.storeWeekDays.schoolSchedule,
            ...this.storeWeekDays.activitySchedule,
          ]
        : []
    },
    getParsedWeekDays() {
      const days = this.getGroupedDays.reduce((acc, weekDay) => {
        if (
          !acc.some(
            ({ day }) => day.toLowerCase() === weekDay.day.toLowerCase()
          )
        ) {
          acc.push(weekDay)
        }
        const dayIndex = acc.findIndex(
          ({ day }) => day.toLowerCase() === weekDay.day.toLowerCase()
        )
        const localDay = acc[dayIndex]
        if (weekDay.subject) {
          if (!localDay.subjects) localDay.subjects = []
          localDay.subjects.push(weekDay.subject)
        }
        if (weekDay.activity) {
          if (!localDay.activities) localDay.activities = []
          localDay.activities.push(weekDay.activity)
        }
        return acc
      }, [])
      return days
    },
    getMappedWeekDays() {
      return this.getParsedWeekDays.map(({ day }) => day.toLowerCase())
    },
    getDays() {
      return this.getParsedWeekDays.length
        ? this.weekDays.map((d) => {
            if (!this.getMappedWeekDays.includes(d)) {
              return {
                day: `${d[0].toUpperCase()}${d.substring(1)}`,
                subjects: [],
                activities: [],
              }
            }
            return this.getParsedWeekDays[
              this.getParsedWeekDays.findIndex(
                ({ day }) => day.toLowerCase() === d
              )
            ]
          })
        : this.weekDays.map((day) => ({
            day: `${day[0].toUpperCase()}${day.substring(1)}`,
            subjects: [],
            activities: [],
          }))
    },
  },
  methods: {
    getCurrentChildren() {
      const childId = this.$route.query.id
      const found = this.myChildren.find((child) => child.id === childId)
      return (this.childPhoto = found.photo)
    },
    ...mapActions({
      getMyChildren: 'users/getMyChildren',
      getChildrenWeekDays: 'children/getChildrenWeekDays',
    }),
  },
  components: {
    HeaderComponent,
    DayComponent,
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/partials/_variables.scss';
.week {
  display: flex;
  flex-direction: column;
  gap: toRem(40);
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: toRem(10);
  }
  &__image {
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 toRem(5) toRem(10) rgb(88, 88, 88);
    padding: toRem(8) toRem(8) 0 toRem(8);
    border-radius: 50%;
    img {
      width: toRem(80);
      height: toRem(80);
      border-radius: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    span {
      font-size: toRem(25);
    }
  }
  &__days {
    display: grid;
    grid-template-columns: repeat(3, toRem(90));
    grid-gap: toRem(20);
    justify-content: center;
    text-transform: uppercase;
  }
  .last-item {
    grid-column: 1 / span 3;
    margin: 0 auto;
  }
}
.dots-flow:before {
  animation: dots-flow 0.85s infinite ease;
  animation: dots-flow 0.85s infinite ease;
  border-radius: 100%;
  content: '';
  height: toRem(16);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: toRem(16);
}

@keyframes dots-flow {
  0%,
  100% {
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
  }
  35% {
    box-shadow: -26px 32px 0 4px #ffe400, 0px 32px 0 0 white,
      26px 32px 0 0 white;
    box-shadow: -26px 32px 0 4px #ffe400, 0px 32px 0 0 white,
      26px 32px 0 0 white;
  }
  50% {
    box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #ffe400,
      26px 32px 0 0 white;
    box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #ffe400,
      26px 32px 0 0 white;
  }
  65% {
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white,
      26px 32px 0 4px #ffe400;
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white,
      26px 32px 0 4px #ffe400;
  }
}

@keyframes dots-flow {
  0%,
  100% {
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
  }
  35% {
    box-shadow: -26px 32px 0 4px #ffe400, 0px 32px 0 0 white,
      26px 32px 0 0 white;
    box-shadow: -26px 32px 0 4px #ffe400, 0px 32px 0 0 white,
      26px 32px 0 0 white;
  }
  50% {
    box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #ffe400,
      26px 32px 0 0 white;
    box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #ffe400,
      26px 32px 0 0 white;
  }
  65% {
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white,
      26px 32px 0 4px #ffe400;
    box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white,
      26px 32px 0 4px #ffe400;
  }
}
</style>
