<template>
  <div class="container">
    <HeaderComponent />
    <div class="profile">
      <h2 class="profile__title">Profile</h2>
      <div class="input">
        <label for="name" class="input__label">Username</label>

        <input
          v-model="username"
          type="text"
          id="name"
          name="name"
          class="input__input"
          disabled
        />
      </div>
      <div class="input">
        <label for="email" class="input__label">Email</label>

        <input
          v-model="email"
          type="text"
          id="email"
          name="email"
          class="input__input"
          disabled
        />
      </div>
      <router-link to="/change-password" class="profile__item"
        >change password</router-link
      >
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      username: '',
      email: '',
    }
  },
  async mounted() {
    await this.getCurrentUser()
    this.username = this.currentUser.username
    this.email = this.currentUser.email
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'users/getCurrentUser',
    }),
  },
  components: {
    HeaderComponent,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_inputs.scss';
.container {
  background-color: $primary-color;
  width: 100%;
  height: 100%;
}
.profile {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 80%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__title {
    margin: toRem(20) 0;
  }
  &__data {
    border-radius: toRem(50);
    padding: toRem(10) toRem(20);
    background-color: $white-color;
    color: $black-color;
    font-size: toRem(16);
    font-weight: 600;
  }
  &__item {
    text-decoration: underline;
    font-size: toRem(13);
    text-align: left;
    margin-left: toRem(2);
  }
  .btn {
    font-size: toRem(15);
    text-align: center;
  }
  .input {
    position: relative;
    width: 100%;
    border: none;
    &__input {
      padding: 0;
      background: transparent;
      border: 0;
      width: 90%;
      height: 100%;
      font-size: 0.8125rem;
      color: black;
      outline: none;
      font-weight: 600;
      margin-top: toRem(30);
      margin-bottom: toRem(7);
    }
    label {
      position: absolute;
      top: 0.4375rem;
      font-size: toRem(13);
      color: rgb(177, 177, 177);
    }
  }
}
</style>
