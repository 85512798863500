<template>
  <header class="header">
    <div class="header__logo">
      <router-link to="/children-list"
        ><img
          class="header__img"
          src="../../assets/icons/neat-logo.png"
          alt=""
        />
        <p class="header__title">NEAT</p>
      </router-link>
    </div>
    <Slide right width="209">
      <nav class="nav">
        <ul class="nav__content">
          <router-link to="/profile"
            ><li class="nav__item">Profile</li>
          </router-link>
          <router-link to="/children-list"
            ><li class="nav__item">My Children</li>
          </router-link>
        </ul>
      </nav>
      <button @click="logoutTest" class="btn btn--logout">Logout</button>
    </Slide>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { Slide } from 'vue-burger-menu'

export default {
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapGetters({
      showSideMenu: 'modals/showSideMenu',
    }),
  },
  methods: {
    logoutTest() {
      this.$store.dispatch('logout')
    },
  },
  components: {
    Slide,
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/_buttons.scss';
@import '@/assets/scss/libs/_side-menu.scss';
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: toRem(25);
  &__img {
    max-width: toRem(50);
    margin-left: toRem(44);
    margin-top: toRem(30);
  }
  &__title {
    text-align: right;
    color: $black-color;
    letter-spacing: toRem(3);
    font-size: toRem(13);
    font-weight: 500;
  }
  .nav {
    &__item {
      list-style: none;
      color: $black-color;
    }
    &__content {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: toRem(20);
      font-weight: bold;
      margin-left: toRem(30);
      margin-top: toRem(50);
    }
  }
  .btn--logout {
    position: absolute;
    bottom: toRem(120);
    background: transparent;
    color: $purple-color;
    font-weight: 600;
    font-size: toRem(15);
    margin-left: toRem(30);
  }
}
</style>
