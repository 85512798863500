<template>
  <div class="conainer">
    <router-link
      :to="{
        path: getRoutePath,
        query: {
          name: this.$route.query.name,
          id: this.$route.query.id,
          day: day.day,
        },
      }"
      ><button class="week__day" :class="getDayClass">
        {{ day.day }}
      </button></router-link
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      childData: {},
    }
  },
  props: {
    day: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      myChildren: 'users/myChildren',
    }),
    getDayClass() {
      if (this.day.subjects?.length && !this.day.activities?.length) {
        return 'week__day--school'
      }
      if (!this.day.subjects?.length && this.day.activities?.length) {
        return 'week__day--activity'
      }
      if (this.day.subjects?.length && this.day.activities?.length) {
        return 'week__day--half'
      }
      return ''
    },
    getRoutePath() {
      return this.day.subjects?.length || this.day.activities?.length
        ? '/current-day'
        : '/day-schedule'
    },
  },
  methods: {
    getCurrentChildren() {
      this.childData = this.myChildren.find(
        ({ id }) => this.$route.query.id === id
      )
      return this.childData
    },
  },
}
</script>

<style lang="scss" scoped>
.week__day {
  background-color: #e6fac6;
  color: #90ce2d;
  width: toRem(90);
  height: toRem(90);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: toRem(10);
  font-weight: 600;
  border-radius: 100%;
  box-shadow: 0 toRem(2) toRem(10) rgb(128, 128, 128);
  border: toRem(2) solid white;
  &--school {
    background: $yellow-color;
    color: $black-color;
  }
  &--activity {
    background: $light-blue-color;
    color: $black-color;
  }
  &--half {
    background: linear-gradient(
      -45deg,
      $light-blue-color 50%,
      $yellow-color 50%
    );
    color: $black-color;
  }
}
</style>
