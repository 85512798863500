<template>
  <div class="wrapper">
    <HeaderComponent />
    <h2 class="title"><span>Welcome</span> {{ userName }}</h2>

    <div class="container">
      <div class="message" v-if="!myChildren.length">There are no children</div>
      <div v-else class="children">
        <ChildCard
          v-for="child in myChildren"
          :key="child.id"
          :children="child"
        />
      </div>
      <div class="button-wrapper">
        <BaseButton />
        <p class="button-wrapper__subtitle">Add another child</p>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import ChildCard from '@/components/ChildCard.vue'
import BaseButton from '@/components/BaseButton.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      userName: '',
    }
  },
  async mounted() {
    await this.getCurrentUser()
    this.userName = this.currentUser.username
  },
  async created() {
    await this.getMyChildren()
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
      myChildren: 'users/myChildren',
    }),
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'users/getCurrentUser',
      getMyChildren: 'users/getMyChildren',
    }),
  },
  components: {
    HeaderComponent,
    ChildCard,
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: $primary-color;
}
.container {
  background-color: $white-color;
  clip-path: ellipse(170% 100% at 50% 100%);
  height: 100%;
}
.title {
  text-align: center;
  margin: toRem(30) toRem(10) toRem(30) toRem(10);
  span {
    font-weight: 400;
  }
}
.message {
  text-align: center;
  text-transform: uppercase;
  font-size: toRem(15);
  padding-top: toRem(100);
}
.children {
  padding-top: toRem(30);
}
.button-wrapper {
  margin-top: toRem(30);
  padding-bottom: toRem(30);
  &__subtitle {
    padding-top: toRem(20);
    color: $secondary-color;
    font-size: toRem(15);
    font-family: sans-serif;
    text-align: center;
  }
}
:deep().add-child__button {
  width: toRem(100);
  margin: 0 auto;
  .user-icon {
    width: 50%;
    &--boy {
      left: -24%;
    }
  }
}
:deep().btn--add-child {
  width: toRem(100);
  height: toRem(100);
  box-shadow: inset toRem(20) toRem(-15) rgb(92, 69, 163);
  .plus-icon {
    font-size: toRem(50);
  }
}
</style>
