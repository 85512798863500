<template>
  <div>
    <div class="child">
      <router-link
        :to="{
          path: '/week-schedule',
          query: { name: children.full_name, id: children.id },
        }"
        class="child__content"
      >
        <div class="child__image">
          <img
            :src="children.photo ?? require('@/assets/images/profile-img.png')"
            alt="profile photo"
          />
        </div>
        <p class="child__name">{{ children.full_name }}</p>
      </router-link>
      <div class="child__icons">
        <font-awesome-icon
          icon="fa-solid fa-trash-can"
          class="icon"
          @click="doDelete(children.id)"
        />
        <router-link
          :to="{
            path: '/edit-child',
            query: { id: children.id },
          }"
          ><font-awesome-icon icon="fa-solid fa-pen" class="icon"
        /></router-link>
      </div>

      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmDialogue from '@/components/ConfirmDialogue.vue'
export default {
  props: {
    children: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      myChildren: 'users/myChildren',
    }),
  },
  methods: {
    ...mapActions({
      deleteChild: 'children/deleteChild',
    }),
    async doDelete(id) {
      try {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Remove',
          message: `Are you sure you want to remove ${this.children.full_name}? It cannot be undone.`,
          okButton: 'Remove',
        })
        if (ok) {
          await this.deleteChild(id)
          this.$store.commit('users/SET_DELETE_CHILD', id)
        }
      } catch (err) {
        if (err.response.status === 400) {
          await this.$refs.confirmDialogue.show({
            message: `You are not allowed`,
            cancelButton: 'OK',
          })
        }
        console.error(err)
      }
    },
  },
  components: {
    ConfirmDialogue,
  },
}
</script>

<style lang="scss" scoped>
.child {
  border-bottom: solid toRem(1) #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: toRem(20) toRem(45) toRem(20) toRem(20);
  &__content {
    display: flex;
    align-items: center;
    gap: toRem(20);
  }
  &__image {
    width: toRem(100);
    box-shadow: 0 toRem(10) toRem(8) rgb(218, 218, 218);
    padding: toRem(7) 0 toRem(7) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    img {
      width: toRem(80);
      height: toRem(80);
      border-radius: 100%;
      object-fit: cover;
    }
  }
  &__name {
    color: $secondary-color;
    font-size: toRem(25);
  }
  &__icons {
    display: flex;
    gap: toRem(30);
    .icon {
      font-size: toRem(20);
      color: rgb(83, 83, 83);
    }
  }
}
</style>
