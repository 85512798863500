<template>
  <popup-modal ref="popup">
    <h2 class="popup-title">{{ title }}</h2>
    <p class="popup-message">{{ message }}</p>
    <div class="btns">
      <button class="btn btn-cancel" @click="_cancel">
        {{ cancelButton }}
      </button>
      <span class="ok-btn" @click="_confirm">{{ okButton }}</span>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue'

export default {
  data() {
    return {
      title: undefined,
      message: undefined,
      okButton: undefined,
      cancelButton: "I'm not sure",

      resolvePromise: undefined,
      rejectPromise: undefined,
    }
  },
  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
    },
  },
  components: { PopupModal },
}
</script>

<style lang="scss" scoped>
.popup-title {
  font-size: toRem(20);
}
.popup-message {
  font-size: toRem(13);
}
.btns {
  display: flex;
  flex-direction: column;
  gap: toRem(10);
  margin-top: toRem(10);
}
.btn-cancel {
  padding: toRem(8) 0;
}
.ok-btn {
  color: red;
  margin: 0 auto;
}
</style>
