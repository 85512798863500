export default {
  namespaced: true,
  state: {
    showSideMenu: false,
  },
  getters: {
    showSideMenu(state) {
      return state.showSideMenu
    },
  },
  mutations: {
    SET_SHOW_SIDE_MENU(state, data) {
      state.showSideMenu = data
    },
  },
}
