var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"child"},[_c('router-link',{staticClass:"child__content",attrs:{"to":{
        path: '/week-schedule',
        query: { name: _vm.children.full_name, id: _vm.children.id },
      }}},[_c('div',{staticClass:"child__image"},[_c('img',{attrs:{"src":_vm.children.photo ?? require('@/assets/images/profile-img.png'),"alt":"profile photo"}})]),_c('p',{staticClass:"child__name"},[_vm._v(_vm._s(_vm.children.full_name))])]),_c('div',{staticClass:"child__icons"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"fa-solid fa-trash-can"},on:{"click":function($event){return _vm.doDelete(_vm.children.id)}}}),_c('router-link',{attrs:{"to":{
          path: '/edit-child',
          query: { id: _vm.children.id },
        }}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"fa-solid fa-pen"}})],1)],1),_c('confirm-dialogue',{ref:"confirmDialogue"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }