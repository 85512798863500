<template>
  <div class="container">
    <HeaderComponent />
    <form class="change-password" @submit.prevent="onSubmit">
      <h2 class="change-password__title">Change Password</h2>
      <div class="change-password__container">
        <label for="current-password" class="change-password__label">{{
          errors.current_password
        }}</label>
        <input
          v-model="current_password"
          type="password"
          name="current-password"
          id="current-password"
          placeholder="Your Current Password"
          class="input change-password__item"
          :class="{ 'red-border': errors.current_password }"
        />
      </div>
      <div class="change-password__container">
        <label for="new-password" class="change-password__label">{{
          errors.new_password
        }}</label>
        <input
          v-model="new_password"
          type="password"
          name="new-password"
          id="new-password"
          placeholder="Your New Password"
          class="input change-password__item"
          :class="{ 'red-border': errors.new_password }"
        />
      </div>
      <div class="change-password__container">
        <label for="confirm-new-password" class="change-password__label">{{
          errors.confirm_new_password
        }}</label>
        <input
          v-model="confirm_new_password"
          type="password"
          name="confirm-new-password"
          id="confirm-new-password"
          placeholder="Confirm New Password"
          class="input change-password__item"
          :class="{ 'red-border': errors.confirm_new_password }"
        />
      </div>
      <button class="btn change-password__item" type="submit">SAVE</button>
      <p class="text-success">{{ generalMessage }}</p>

      <template v-if="generalMessage">
        <button @click="logoutTest" class="btn btn--login">
          Login with new password
        </button></template
      >
    </form>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      current_password: null,
      new_password: null,
      confirm_new_password: null,
      errors: {
        current_password: null,
        new_password: null,
        confirm_new_password: null,
      },
      generalMessage: null,
    }
  },
  methods: {
    async onSubmit() {
      if (!this.current_password)
        this.errors.current_password = 'Current Password Required'
      else this.errors.current_password = null
      if (!this.new_password) this.errors.new_password = 'New Password Required'
      else this.errors.new_password = null
      if (!this.confirm_new_password)
        this.errors.confirm_new_password = 'Confirm New Password'
      else this.errors.confirm_new_password = null

      if (
        this.current_password &&
        this.new_password &&
        this.confirm_new_password
      ) {
        try {
          const response = await this.changePassword({
            current_password: this.current_password,
            new_password: this.new_password,
            password_confirm: this.confirm_new_password,
          })
          if (response.status == 200) {
            this.generalMessage = 'Changed successfully'
          }
        } catch (err) {
          if (err.response.data.error.includes('incorrect'))
            this.errors.current_password = 'Old password is incorrect!'
          if (err.response.data.error.includes('same'))
            this.errors.new_password =
              'Old password and New password are the same!'
          let msg = err.response.data.message[0]
          if (msg.includes('match'))
            this.errors.confirm_new_password = "Password confirm doesn't match."
          if (msg.includes('new_password must be longer'))
            this.errors.new_password =
              'New password must be longer than or equal to 6 characters'
        }
      }
    },
    logoutTest() {
      this.$store.dispatch('logout')
    },
    ...mapActions(['changePassword']),
  },
  components: {
    HeaderComponent,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_inputs.scss';
.container {
  background-color: $primary-color;
  width: 100%;
  height: 100%;
}
.change-password {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 80%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__title {
    text-align: center;
  }
  &__item {
    width: 100%;
    box-sizing: border-box;
    padding: toRem(18) toRem(10) toRem(18) toRem(20);
    font-size: toRem(13);
  }
  &__container {
    position: relative;
  }
  &__label {
    position: absolute;
    font-size: toRem(10);
    left: toRem(20);
    top: toRem(4);
    color: red;
  }
  .text-success {
    color: green;
    font-weight: 700;
    text-align: center;
  }
  .red-border {
    border: toRem(1) solid red;
  }
  .btn--login {
    background: transparent;
    color: $black-color;
    text-align: center;
  }
}
</style>
