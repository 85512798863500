<template>
  <div class="container">
    <div class="day">
      <div class="day__title">
        <div class="day__image">
          <img
            :src="childPhoto ?? require('@/assets/images/profile-img.png')"
          />
        </div>
        <h3 class="day__name">
          {{ this.$route.query.name }} on <br /><span>{{
            this.$route.query.day
          }}</span>
        </h3>
      </div>
      <form class="day__data" @submit.prevent="onSubmit">
        <div class="day__error">
          <font-awesome-icon
            icon="fa-solid fa-circle-exclamation"
            v-if="generalError || error"
          />
          <p class="general-error" v-if="generalError">{{ generalError }}</p>
          <p class="general-error" v-if="error">{{ error }}</p>
        </div>
        <div class="day__radio-buttons">
          <label>
            <input
              type="radio"
              id="school"
              value="School"
              name="radio"
              v-model="picked"
            />
            <span>School</span>
          </label>
          <label>
            <input
              type="radio"
              id="activity"
              value="Activity"
              name="radio"
              v-model="picked"
            />
            <span>Activity</span>
          </label>
        </div>
        <multiselect
          v-if="picked === 'School'"
          v-model="value"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="School"
          label="name"
          track-by="name"
          :options="getAllSubjects"
          :multiple="true"
          ref="multiselect"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
          <template slot="option" slot-scope="props">
            <div class="multiselect__item">
              <span>{{ props.option.name }}</span>
            </div>
          </template>
          <template slot="beforeList">
            <button
              v-if="value.length"
              type="button"
              @click="clearTabs()"
              class="btn multiselect__clear"
            >
              Clear All
            </button>
          </template>
        </multiselect>
        <multiselect
          v-else-if="picked === 'Activity'"
          v-model="value"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Activity"
          label="name"
          track-by="name"
          :options="getAllActivities"
          :multiple="true"
          ref="multiselect"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
          <template slot="option" slot-scope="props">
            <div class="multiselect__item">
              <span>{{ props.option.name }}</span>
            </div>
          </template>
          <template slot="beforeList">
            <button
              v-if="value.length"
              type="button"
              @click="clearTabs()"
              class="btn multiselect__clear"
            >
              Clear All
            </button>
          </template>
        </multiselect>
        <div class="day__selected">
          <p v-for="(val, index) in getSchoolData" :key="index" class="school">
            {{ val.subject.name }}
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="x-icon"
              @click="deleteSubject(val.id, index)"
            />
          </p>
          <p
            v-for="(val, i) in getActivityData"
            :key="'A' + i"
            class="activity"
          >
            {{ val.activity.name }}
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="x-icon"
              @click="deleteActivity(val.id, i)"
            />
          </p>
        </div>
        <div class="day__selected">
          <p v-for="(val, index) in value" :key="index" :class="`${val.type}`">
            {{ val.name }}
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="x-icon"
              @click="deleteFromList(index)"
            />
          </p>
        </div>
        <div>
          <label class="time-date">Time</label>
          <div class="day__time" v-if="picked === 'School'">
            <div class="day__button-starts-ends">
              <select
                name="time"
                id="time"
                v-model="schoolStarts"
                class="btn day__btn--starts"
              >
                <option disabled value="">Starts</option>
                <option v-for="time in foo" :key="time">
                  {{ time }}
                </option>
              </select>
            </div>
            <div class="day__button-starts-ends">
              <select
                name="time"
                id="time"
                v-model="schoolEnds"
                class="btn day__btn--starts"
              >
                <option disabled value="">Ends</option>
                <option v-for="time in foo" :key="time">{{ time }}</option>
              </select>
            </div>
          </div>
          <div class="day__time" v-if="picked === 'Activity'">
            <div
              class="day__button-starts-ends"
              :class="{ 'red-border-radius': error }"
            >
              <select
                name="time"
                id="time"
                v-model="activityStarts"
                class="btn day__btn--starts"
              >
                <option disabled value="">Starts</option>
                <option v-for="time in foo" :key="time">{{ time }}</option>
              </select>
            </div>
            <div
              class="day__button-starts-ends"
              :class="{ 'red-border-radius': error }"
            >
              <select
                name="time"
                id="time"
                v-model="activityEnds"
                class="btn day__btn--starts"
              >
                <option disabled value="">Ends</option>
                <option v-for="time in foo" :key="time">{{ time }}</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <label class="time-date">Date</label>
          <div class="day__date" v-if="picked === 'School'">
            <div class="day__from day__from-to--arrow-down">
              <date-time
                v-model="schoolDateFrom"
                class="theme-green"
                placeholder="From"
              ></date-time>
              <font-awesome-icon
                icon="fa-solid fa-angle-down"
                class="day__btn--starts-down-arrow"
              />
            </div>
            <div class="day__to day__from-to--arrow-down">
              <date-time
                v-model="schoolDateTo"
                class="theme-green"
                placeholder="To"
              ></date-time>
              <font-awesome-icon
                icon="fa-solid fa-angle-down"
                class="day__btn--starts-down-arrow"
              />
            </div>
          </div>
          <div class="day__date" v-if="picked === 'Activity'">
            <div
              class="day__from day__from-to--arrow-down"
              :class="{ 'red-border-radius': error }"
            >
              <date-time
                v-model="activityDateFrom"
                class="theme-green"
                placeholder="From"
              ></date-time>
              <font-awesome-icon
                icon="fa-solid fa-angle-down"
                class="day__btn--starts-down-arrow"
              />
            </div>
            <div
              class="day__to day__from-to--arrow-down"
              :class="{ 'red-border-radius': error }"
            >
              <date-time
                v-model="activityDateTo"
                class="theme-green"
                placeholder="To"
              ></date-time>
              <font-awesome-icon
                icon="fa-solid fa-angle-down"
                class="day__btn--starts-down-arrow"
              />
            </div>
          </div>
        </div>
        <div class="day__comment" v-if="picked === 'School'">
          <textarea
            v-model="schoolComment"
            name="comment"
            id="comment"
            cols="15"
            rows="5"
            placeholder="Comment"
          ></textarea>
        </div>
        <div class="day__comment" v-if="picked === 'Activity'">
          <textarea
            v-model="activityComment"
            name="comment"
            id="comment"
            cols="15"
            rows="5"
            placeholder="Comment"
          ></textarea>
        </div>
        <div class="day__reminder">
          <button
            type="button"
            class="btn day__p"
            :class="{ 'fa-circle-exclamation': generalError }"
            @click="setReminder()"
          >
            <font-awesome-icon icon="fa-regular fa-bell" /> Set Reminder
          </button>
          <p>{{ schoolReminder }}</p>
        </div>
        <button class="btn day__save-button" type="submit">Save</button>
      </form>
      <set-reminder
        ref="setReminder"
        v-on:child-method="updateParent"
      ></set-reminder>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import SetReminder from '@/components/SetReminder.vue'
import { mapActions, mapGetters } from 'vuex'
import api from '@/api/api'

export default {
  data() {
    return {
      childPhoto: null,
      day: null,
      value: [],
      schoolStarts: null,
      schoolEnds: null,
      activityStarts: null,
      activityEnds: null,
      schoolDateFrom: null,
      schoolDateTo: null,
      activityDateFrom: null,
      activityDateTo: null,
      schoolComment: null,
      activityComment: null,
      picked: 'School',
      generalError: null,
      error: null,
      schoolReminder: '',
      interval: 15,
      startDate: 60 * 6,
      endDate: 60 * 24,
      foo: null,
      getSchoolData: [],
      getActivityData: [],
      getType: null,
      schoolScheduleID: null,
    }
  },
  async mounted() {
    let firstLetter = this.$route.query.day.charAt(0).toUpperCase()
    let restLetters = this.$route.query.day.slice(1)
    this.day = firstLetter + restLetters
    this.schoolData
    this.activityData
    this.getTime
    this.getEndTime
  },
  async created() {
    await this.getMyChildren()
    this.getCurrentChildren()
    this.getSubjects()
    this.getActivities()
    this.setData()
  },
  computed: {
    ...mapGetters({
      myChildren: 'users/myChildren',
      getAllSubjects: 'subjects/getAllSubjects',
      getAllActivities: 'activities/getAllActivities',
      getDaySchool: 'daySchedule/getDaySchool',
      getDayActivity: 'daySchedule/getDayActivity',
    }),
    schoolData() {
      return this.getDaySchool.map((element) => {
        this.getSchoolData.push(element)
      })
    },
    activityData() {
      return this.getDayActivity.map((element) => {
        this.getActivityData.push(element)
      })
    },
    getTime() {
      let startTime = this.getSchoolData[0]?.start_time.slice(0, 5)
      return startTime
    },
    getEndTime() {
      let endTime = this.getSchoolData[0]?.end_time.slice(0, 5)
      return endTime
    },
  },
  watch: {
    value(value) {
      value.forEach((el) => {
        if (!el.type) {
          el.name = el.name.trim()
          el.type = this.picked.toLowerCase()
        }
      })
    },
  },
  methods: {
    deleteFromList(index) {
      this.value.splice(index, 1)
    },
    getCurrentChildren() {
      const childId = this.$route.query.id
      const found = this.myChildren.find((child) => child.id === childId)
      return (this.childPhoto = found.photo)
    },
    async onSubmit() {
      if (!this.value.length) {
        this.generalError = 'You need to fill school or activity schedule'
      } else {
        try {
          const schoolData = {
            day: this.day,
            name: this.value
              .filter(({ type }) => type === 'school')
              .map(({ id }) => id),
            end_time: this.schoolEnds,
            start_time: this.schoolStarts,
            from: this.schoolDateFrom,
            to: this.schoolDateTo,
            notification_time: this.schoolReminder,
          }
          const activityData = {
            day: this.day,
            name: this.value
              .filter(({ type }) => type === 'activity')
              .map(({ id }) => id),
            end_time: this.activityEnds,
            start_time: this.activityStarts,
            from: this.activityDateFrom,
            to: this.activityDateTo,
            comment: this.activityComment,
          }
          if (schoolData.name.length && !this.getSchoolData.length) {
            await api({ requiresAuth: true }).post(
              `/school-schedule/${this.$route.query.id}/create`,
              schoolData
            )
          }
          if (activityData.name.length) {
            await api({ requiresAuth: true }).post(
              `/activity-schedule/create/${this.$route.query.id}`,
              activityData
            )
          }
          this.$router.push({
            path: '/current-day',
            query: {
              name: this.$route.query.name,
              id: this.$route.query.id,
              day: this.$route.query.day,
            },
          })
        } catch (err) {
          let reminderError =
            err.response.data.message[0].includes('notification')
          let dayError = err.response.data.message.includes(
            'has an school schedule!'
          )
          if (reminderError) {
            this.generalError = 'Set time for school reminder'
          }
          if (dayError) {
            this.error = 'Your child for this day has a school schedule!'
          }
        }
      }
    },
    async setReminder() {
      try {
        const ok = await this.$refs.setReminder.show({
          title: 'Set School reminder',
          okButton: 'Set Reminder',
        })
        if (ok) {
          this.schoolReminder
        }
      } catch (err) {
        console.error(err)
      }
    },
    updateParent(value_from_child) {
      this.schoolReminder = value_from_child
    },
    generateHoursInterval(startHourInMinute, endHourInMinute, interval) {
      const times = []
      for (let i = 0; startHourInMinute < 24 * 60; i++) {
        if (startHourInMinute > endHourInMinute) break
        let hh = Math.floor(startHourInMinute / 60)
        let mm = startHourInMinute % 60
        times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2)
        startHourInMinute = startHourInMinute + interval
      }
      return times
    },
    setData() {
      this.foo = this.generateHoursInterval(
        this.startDate,
        this.endDate,
        this.interval
      )
    },
    async deleteSubject(id, index) {
      try {
        await api({ requiresAuth: true })
          .delete(`/school-schedule/${id}/delete`)
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit('daySchedule/SET_DELETE_SUBJECT', id)
              this.getSchoolData.splice(index, 1)
            }
          })
      } catch (err) {
        console.error(err)
      }
    },
    async deleteActivity(id, index) {
      try {
        await api({ requiresAuth: true })
          .delete(`/activity-schedule/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit('daySchedule/SET_DELETE_ACTIVITY', id)
              this.getActivityData.splice(index, 1)
            }
          })
      } catch (err) {
        console.error(err)
      }
    },
    clearTabs() {
      this.value = []
      this.$refs.multiselect.deactivate()
    },
    ...mapActions({
      getMyChildren: 'users/getMyChildren',
      getSubjects: 'subjects/getSubjects',
      getActivities: 'activities/getActivities',
      schoolSchedule: 'subjects/schoolSchedule',
    }),
  },
  destroyed() {
    this.$store.commit('daySchedule/CLEAR_DAY_SCHEDULE')
  },
  components: {
    SetReminder,
    Multiselect,
  },
}
</script>

<style lang="scss">
.day {
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: toRem(10);
    margin-bottom: toRem(80);
  }
  &__image {
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 toRem(5) toRem(10) rgb(88, 88, 88);
    padding: toRem(7) toRem(8) 0 toRem(8);
    border-radius: 50%;
    img {
      width: toRem(80);
      height: toRem(80);
      border-radius: 100%;
      object-fit: cover;
    }
  }
  &__data {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: toRem(20);
    input[type='radio'] {
      display: none;
    }
    label {
      cursor: pointer;
      font-weight: 500;
      margin-bottom: toRem(10);
      & span {
        display: inline-flex;
        align-items: center;
        padding: toRem(5) toRem(20) toRem(5) toRem(10);
        border-radius: toRem(30);
        transition: 0.25s ease;
        &:before {
          content: '';
          background-color: #fff;
          width: toRem(20);
          height: toRem(20);
          border-radius: 50%;
          margin-right: toRem(10);
          transition: 0.25s ease;
          box-shadow: inset 0 0 0 toRem(2) $secondary-color;
        }
      }
    }
    input[type='radio']:checked + span:before {
      box-shadow: inset 0 0 0 toRem(10) $secondary-color;
    }
    .general-error {
      text-align: center;
      color: red;
      font-size: toRem(13);
    }
    .red-border {
      border: toRem(1) solid red;
    }
    .red-border-radius {
      border: toRem(1) solid red;
      border-radius: toRem(50);
    }
    .x-icon {
      font-size: toRem(15);
    }
  }
  &__selected {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: toRem(10);
    p {
      padding: toRem(5) toRem(15);
      border-radius: toRem(50);
      display: flex;
      align-items: center;
      gap: toRem(10);
    }
    .school {
      background: #f8f7eb;
      color: $black-color;
    }
    .activity {
      background: $light-blue-color;
    }
  }
  .option__image {
    width: toRem(10);
  }
  &__name {
    span {
      color: $white-color;
      font-size: toRem(30);
      text-transform: capitalize;
    }
  }
  &__time {
    display: flex;
    gap: toRem(10);
  }
  &__button-starts-ends {
    width: 50%;
  }
  &__btn--starts {
    width: 100%;
    padding: toRem(10) 0 toRem(10) toRem(15);
    box-sizing: border-box;
    background: $secondary-color url(../assets/icons/arrow-down-green.svg)
      no-repeat right toRem(12) center;
    font-family: $font-family;
    outline: none;
    color: $primary-color;
    appearance: none;
    &-down-arrow {
      position: absolute;
      top: toRem(12);
      right: toRem(17);
    }
  }
  [type='time']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  &__date {
    display: flex;
    gap: toRem(10);
  }
  &__from-to--arrow-down {
    position: relative;
    width: 50%;
    ::placeholder {
      color: $white-color;
    }
  }
  &__comment {
    textarea {
      width: 100%;
      border-radius: toRem(20);
      padding: toRem(10) 0 0 toRem(20);
      box-sizing: border-box;
      background-color: #f3f2f7;
      box-shadow: inset 0 toRem(1) rgb(202, 202, 202);
      border: none;
      resize: none;
      outline: none;
      &::placeholder {
        font-family: Poppins;
        color: $black-color;
      }
    }
  }
  &__save-button {
    width: 100%;
    background-color: $primary-color;
    color: $black-color;
    padding: toRem(10) 0;
    font-size: toRem(15);
    margin-bottom: toRem(20);
  }
  .time-date {
    padding-left: toRem(10);
  }
  &__reminder {
    display: flex;
    align-items: center;
  }
  &__p {
    background: transparent;
    color: black;
    text-align: left;
    font-size: toRem(15);
  }
  .fa-circle-exclamation {
    color: red;
  }
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: toRem(10);
  }
  .multiselect__item {
    margin-left: toRem(10);
  }
  .multiselect__clear {
    font-size: toRem(11);
    margin-bottom: toRem(15);
    position: absolute;
    right: toRem(22);
    z-index: 1;
  }
}
</style>
