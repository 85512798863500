import Vue from 'vue'
import VueRouter from 'vue-router'
import LogIn from '@/pages/LogIn'
import SignUp from '@/pages/SignUp'
import AddChild from '@/pages/AddChild'
import ChildPage from '@/pages/ChildPage'
import WeekSchedule from '@/pages/WeekSchedule'
import DaySchedule from '@/pages/DaySchedule'
import ChildrenList from '@/pages/ChildrenList'
import SignupPage from '@/pages/SignupPage.vue'
import CurrentDay from '@/pages/CurrentDay.vue'
import AddParent from '@/pages/AddParent'
import WeekScheduleWithData from '@/pages/WeekScheduleWithData'
import ProfilePage from '@/pages/ProfilePage.vue'
import ChangePassword from '@/pages/ChangePassword.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import EditChild from '@/pages/EditChild.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LogIn,
    meta: { guest: true },
  },
  {
    path: '/',
    name: 'Home',
    component: SignUp,
  },
  {
    path: '/add-child',
    name: 'AddChild',
    component: AddChild,
    meta: { requiresAuth: true },
  },
  {
    path: '/child',
    name: 'ChildPage',
    component: ChildPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/week-schedule',
    name: 'WeekSchedule',
    component: WeekSchedule,
    meta: { requiresAuth: true },
  },
  {
    path: '/day-schedule',
    name: 'DaySchedule',
    component: DaySchedule,
    meta: { requiresAuth: true },
  },
  {
    path: '/children-list',
    name: 'ChildrenList',
    component: ChildrenList,
    meta: { requiresAuth: true },
  },
  {
    path: '/auth/register',
    name: 'Signup',
    component: SignupPage,
    meta: { guest: true },
    params: true,
    children: [{ path: ':token', name: 'SignupToken', component: SignupPage }],
  },
  {
    path: '/current-day',
    name: 'CurrentDay',
    component: CurrentDay,
    meta: { requiresAuth: true },
  },
  {
    path: '/add-parent',
    name: 'AddParent',
    component: AddParent,
    meta: { requiresAuth: true },
  },
  {
    path: '/week-schedule-data',
    name: 'WeekScheduleWithData',
    component: WeekScheduleWithData,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/edit-child',
    name: 'EditChild',
    component: EditChild,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('access_token')
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({ name: 'Login' })
  } else if (to.matched.some((record) => record.meta.guest) && loggedIn) {
    next({ name: 'AddChild' })
  } else next()
})

export default router
