import api from '@/api/api'
export default {
  namespaced: true,
  state: {
    children: [],
    deletedChild: false,
    weekDays: [],
  },
  getters: {
    children: (state) => state.children,
    weekDays: (state) => state.weekDays,
  },
  mutations: {
    SET_DELETE_STATE(state, statusCode) {
      state.deletedChild = statusCode
    },
    UPDATE_CHILD(state, childData) {
      let findChild = state.children.find((child) => child.id == childData.id)
      findChild.full_name = childData.full_name
      findChild.age = childData.age
      findChild.class = childData.class
    },
    SET_WEEK_DAYS(state, data) {
      state.weekDays = data
    },
  },
  actions: {
    createChild(_, childrenData) {
      return api({ requiresAuth: true }).post('/child/create', childrenData)
    },
    uploadChildImage: (_, { childId, file }) => {
      try {
        return api({ requiresAuth: true }).post(
          `/child/upload/${childId}`,
          file,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
      } catch (err) {
        console.error(err)
      }
    },
    deleteChild(_, childId) {
      return api({ requiresAuth: true }).delete(`/child/${childId}/delete`)
    },
    editChild(_, data) {
      return api({ requiresAuth: true }).put(`/child/${data.id}/update`, data)
    },
    async getChildrenWeekDays({ commit }, childId) {
      try {
        const { data } = await api({ requiresAuth: true }).get(
          `/child/getMyChildWeek/${childId}`
        )
        commit('SET_WEEK_DAYS', data)
      } catch (err) {
        console.error(err)
      }
    },
  },
}
