<template>
  <div class="login-wrapper">
    <div class="logo">
      <img class="logo__img" src="../assets/icons/neat-logo.png" alt="" />
      <p class="logo__title">NEAT</p>
    </div>
    <div class="login">
      <h2 class="login__title">Log In</h2>
      <form class="login__form" @submit.prevent="onSubmit">
        <div class="login__email">
          <label for="password" class="login__label--error" v-if="errors">{{
            this.errors.email || errors.generalError
          }}</label>
          <input
            type="email"
            id="email"
            v-model="email"
            name="email"
            placeholder="Email"
            class="input"
            :class="{ 'red-border': errors.email || errors.generalError }"
          />
        </div>
        <div class="login__email">
          <label for="password" class="login__label--error" v-if="errors">{{
            this.errors.password
          }}</label>
          <input
            id="password"
            v-model="password"
            placeholder="Password"
            type="password"
            class="input"
            :class="{ 'red-border': errors.password || errors.generalError }"
          />
        </div>

        <div class="login__email">
          <button type="submit" value="Let's planning" class="btn btn--login">
            Let's planning
          </button>
        </div>
      </form>
      <div class="login__links">
        <router-link to="/auth/register" class="login__link"
          >New User? <span>Register</span></router-link
        >
        <router-link to="/forgot-password" class="login__link"
          >Forgot password?</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LogIn',

  data() {
    return {
      email: null,
      password: null,
      userLogIn: [],
      errors: {
        email: null,
        password: null,
        generalError: '',
      },
    }
  },
  mounted() {
    this.$store.commit('modals/SET_SHOW_SIDE_MENU', false)
  },
  computed: {
    ...mapGetters({
      showSideMenu: 'modals/showSideMenu',
    }),
  },
  methods: {
    async onSubmit() {
      if (!this.email) this.errors.email = 'Email Required'
      else this.errors.email = null
      if (!this.password) {
        this.errors.password = 'Password Required'
      } else this.errors.password = null

      if (this.email && this.password) {
        try {
          const {
            data: { user, access_token },
          } = await this.$store.dispatch('login', {
            email: this.email,
            password: this.password,
          })

          this.$store.commit('SET_USER_DATA', user)
          localStorage.setItem('access_token', access_token)
          this.$router.push({ name: 'AddChild' })
        } catch (err) {
          if (err.response.status == 400 || err.response.status == 422) {
            this.errors.generalError = 'Invalid email or password'
          }
          if (err.response.status == 404) {
            this.errors.generalError = 'User does not exist'
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_buttons.scss';
@import '@/assets/scss/partials/_inputs.scss';

.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $primary-color;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__img {
    width: toRem(80);
    object-fit: cover;
  }
  &__title {
    text-align: center;
    font-size: toRem(20);
    letter-spacing: toRem(7);
    margin-top: toRem(10);
    font-weight: 500;
  }
}
.btn-router {
  width: 80%;
}
.btn--login {
  width: 100%;
  padding: toRem(15) 0 toRem(15) toRem(20);
}
.login {
  overflow: hidden;
  &__form {
    display: flex;
    flex-direction: column;
    gap: toRem(30);
    padding: 0 toRem(50);
  }
  &__title {
    text-align: center;
    padding-top: toRem(40);
    padding-bottom: toRem(20);
  }
  &__links {
    display: flex;
    justify-content: space-between;
    font-size: toRem(13);
    margin-top: toRem(25);
    padding: 0 toRem(60);
  }
  &__link {
    color: black;
    font-size: toRem(13);
    margin-bottom: toRem(10);
    span {
      color: $secondary-color;
      font-weight: 600;
    }
  }
  p {
    margin-left: toRem(10);
  }
  &__email {
    position: relative;
  }
  &__label--error {
    position: absolute;
    font-size: toRem(10);
    left: toRem(20);
    top: toRem(4);
    color: red;
  }
}
.red-border {
  border: toRem(1) solid red;
}
.input {
  width: 100%;
  padding: toRem(18) 0 toRem(18) toRem(20);
  box-sizing: border-box;
}
</style>
