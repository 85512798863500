<template>
  <div class="wrapper">
    <vue-splash
      :show="true"
      :logo="logo"
      color="#9584B8"
      :size="150"
      :fixed="fixed"
      :class="isFalse"
    />
    <div class="container">
      <div class="logo">
        <img class="logo__img" src="../assets/icons/neat-logo.png" alt="" />
        <p class="logo__title">NEAT</p>
      </div>
      <div class="signup">
        <router-link class="btn btn--white" to="/auth/register"
          >Sign up for free</router-link
        >

        <button class="btn btn--facebook">Continue with facebook</button>
        <router-link :to="{ name: 'Login' }" class="btn btn--transparent"
          >Login</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import NeatLogo from '@/assets/icons/neat-logo.png'
export default {
  name: 'SignUp',
  data() {
    return {
      fixed: true,
    }
  },
  mounted() {
    setTimeout(() => (this.fixed = false), 2000)
  },
  computed: {
    logo() {
      return NeatLogo
    },
    isFalse() {
      if (!this.fixed) {
        return 'splash-disappear'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_buttons.scss';
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary-color;
}
.signup {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: toRem(13);
  margin-bottom: toRem(160);
}
.logo {
  margin: 0 auto;
  padding-top: toRem(134);
  &__img {
    width: toRem(120);
  }
  &__title {
    text-align: center;
    font-size: toRem(30);
    letter-spacing: toRem(7);
    margin-top: toRem(10);
    font-weight: 500;
  }
}
.btn--white {
  background-color: $white-color;
  color: $black-color;
  padding: toRem(20) 0;
  width: 90%;
  text-align: center;
}
.btn--facebook {
  background-color: $facebook-color;

  padding: toRem(20) 0;
  color: $white-color;
  width: 90%;
}
.btn--transparent {
  background-color: transparent;
  color: $black-color;
  text-align: center;

  text-decoration: none;
}
.vue-splash--fixed {
  background-color: #aaed3f !important;
}
.splash-disappear {
  display: none !important;
}
.vue-splash__anim {
  animation-duration: 2s;
}
</style>
