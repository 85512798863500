<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="container">
      <div class="child">
        <h2 class="child__title">Add Child</h2>
        <form class="child__data" @submit.prevent="onSubmit">
          <div class="child__age">
            <label for="age" class="child__label">{{ errors.name }}</label>
            <input
              type="text"
              v-model="childrenData.full_name"
              class="input child__name"
              placeholder="Name"
              :class="{ 'red-border': errors.name }"
            />
          </div>
          <div class="child__age-class">
            <div class="child__age">
              <label for="age" class="child__label">{{ errors.age }}</label>
              <select
                name="age"
                id="age"
                v-model="childrenData.age"
                class="input input-select"
                :class="{ 'red-border': errors.age }"
              >
                <option disabled value="">Age</option>
                <option v-for="age in 15" :key="age">
                  {{ age }}
                </option>
              </select>
            </div>
            <div class="child__class">
              <label for="age" class="child__label">{{ errors.gender }}</label>
              <select
                name="gender"
                id="gender"
                class="input input-select"
                :class="{ 'red-border': errors.gender }"
                v-model="childrenData.gender"
              >
                <option disabled value="">Gender</option>
                <option v-for="gender in GENDERS" :key="gender">
                  {{ gender }}
                </option>
              </select>
            </div>
          </div>
          <div class="child__age">
            <label for="age" class="child__label">{{ errors.class }}</label>
            <select
              name="class"
              id="class"
              class="input input-select"
              :class="{ 'red-border': errors.class }"
              v-model="childrenData.class"
            >
              <option disabled value="">Class</option>
              <option v-for="grade in 9" :key="grade">{{ grade }}</option>
            </select>
          </div>
          <div class="child__photo" v-if="!getImagePreview && !selectedFile">
            <input
              style="display: none"
              type="file"
              @change="onFileSelected"
              ref="fileInput"
            />
            <button
              @click="$refs.fileInput.click()"
              class="input file__input"
              :class="[selectedFile && selectedFile.type]"
              type="button"
            >
              <template v-if="!selectedFile">
                <font-awesome-icon
                  v-if="true"
                  :icon="['fa-solid', 'fa-camera']"
                  class="photo-icon cam-icon"
                />
              </template>
              <span v-if="!selectedFile">Photo of your child</span>
            </button>
          </div>
          <div
            class="child__photo-update"
            v-if="getImagePreview || selectedFile"
          >
            <div class="child__photo-update__edit">
              <input
                style="display: none"
                type="file"
                @change="onFileSelected"
                ref="fileInput"
              />
              <button
                @click="$refs.fileInput.click()"
                type="button"
                class="button"
              >
                <span
                  class="button__photo-wrapper"
                  :class="{ 'red-border': !controlImage }"
                >
                  <img
                    :src="getImagePreview || selectedFile"
                    alt="child photo"
                    ref="image"
                    class="photo"
                  />
                </span>
                Edit Photo
              </button>
            </div>
            <div class="child__photo-update__remove">
              <button @click="removePhoto" type="button">Remove Photo</button>
            </div>
          </div>
          <button
            class="btn child__go-back-btn"
            type="submit"
            @click="clickedSaveButton"
          >
            <font-awesome-icon
              icon="fa-solid fa-arrow-left"
              class="arrow-icon-left"
            />Save and go back
          </button>
          <div class="proceed">
            <div class="proceed__button">
              <button
                class="btn proceed__button--btn"
                type="submit"
                @click="clickedProceedButton"
              >
                <font-awesome-icon
                  icon="fa-solid fa-arrow-right"
                  class="arrow-icon-right"
                />
              </button>
            </div>
            <p class="proceed__subtitle">Proceed to schedule</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import { mapActions, mapGetters } from 'vuex'
import { ALLOWED_EXTENSION, GENDERS } from '@/store/modules/constants'
export default {
  name: 'ChildPage',
  data() {
    return {
      selectedFile: null,
      GENDERS,
      proceed: true,
      childrenData: {
        full_name: '',
        age: '',
        gender: '',
        class: '',
      },
      errors: {
        name: null,
        age: null,
        gender: null,
        class: null,
      },
    }
  },
  computed: {
    ...mapGetters({ myChildren: 'users/myChildren' }),
    controlImage() {
      if (this.selectedFile && this.selectedFile.type) {
        return ALLOWED_EXTENSION.indexOf(this.selectedFile.type) > -1
      }
      return 'No Image'
    },
    getImagePreview() {
      return this.selectedFile ? URL.createObjectURL(this.selectedFile) : null
    },
  },
  methods: {
    async onSubmit() {
      this.errors.name = this.childrenData.full_name ? '' : 'Name is required'
      this.errors.age = this.childrenData.age ? '' : 'Age is required'
      this.errors.gender = this.childrenData.gender ? '' : 'Gender is required'
      this.errors.class = this.childrenData.class ? '' : 'Class is required'
      if (Object.values(this.childrenData).every((value) => value)) {
        try {
          const { data } = await this.createChild(this.childrenData)
          if (this.selectedFile) {
            const formData = new FormData()
            formData.append('file', this.selectedFile)
            if (ALLOWED_EXTENSION.indexOf(this.selectedFile.type) > -1) {
              await this.uploadChildImage({
                childId: data.id,
                file: formData,
              })
            } else {
              this.selectedFile = null
            }
          }
          this.$store.commit('users/SET_CHILDREN', [...this.myChildren, data])
          if (this.proceed) {
            this.$router.push({
              path: '/week-schedule',
              query: {
                name: this.childrenData.full_name,
                id: data.id,
              },
            })
          } else this.$router.push('/children-list')
        } catch (err) {
          console.error(err.response)
        }
      }
    },
    clickedSaveButton() {
      this.proceed = false
    },
    clickedProceedButton() {
      this.proceed = true
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0]
    },
    removePhoto() {
      this.selectedFile = null
    },
    ...mapActions({
      createChild: 'children/createChild',
      uploadChildImage: 'children/uploadChildImage',
    }),
  },
  components: {
    HeaderComponent,
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/partials/_buttons.scss';
@import '@/assets/scss/partials/_inputs.scss';
:deep().header {
  margin-bottom: 0;
}
.child {
  &__data {
    display: flex;
    flex-direction: column;
    gap: toRem(20);
  }
  max-width: 80%;
  margin: 0 auto;
  width: 100%;
  &__title {
    margin-bottom: toRem(33);
    text-align: center;
  }
  &__age-class {
    display: flex;
    gap: toRem(20);
  }
  &__age {
    width: 100%;
    position: relative;
  }
  &__class {
    width: 100%;
    position: relative;
  }
  &__label {
    position: absolute;
    font-size: toRem(10);
    left: toRem(20);
    top: toRem(5);
    color: red;
  }
  .red-border {
    border: toRem(1) solid red;
  }
  .green-border {
    border: toRem(2) solid green;
    color: green;
  }
  .input {
    width: 100%;
    font-size: toRem(15);
    padding: toRem(18) 0 toRem(18) toRem(20);
    font-family: $font-family;
    &-select {
      background: #fff url(../assets/icons/arrow-down.svg) no-repeat right
        toRem(12) center;
      background-size: toRem(28) toRem(13);
      appearance: none;
    }
  }
  &__photo-update {
    display: flex;
    justify-content: center;
    width: 100%;
    &__edit {
      flex: 1;
      .button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: toRem(10);
        border-top-left-radius: toRem(50);
        border-bottom-left-radius: toRem(50);
        border: none;
        padding: toRem(13) 0;
        &__photo-wrapper {
          width: toRem(49);
          max-width: toRem(49);
          height: toRem(35);
          overflow: hidden;
          border-radius: toRem(50);
          object-fit: cover;
          .photo {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &__remove {
      flex: 1;
      button {
        width: 100%;
        height: 100%;
        border: none;
        padding: toRem(15);
        background: $secondary-color;
        border-top-right-radius: toRem(50);
        border-bottom-right-radius: toRem(50);
        color: $white-color;
      }
    }
  }
  &__name {
    caret-color: $primary-color;
    box-sizing: border-box;
    padding: toRem(20) 0 toRem(20) toRem(20);
  }
  .file__input {
    padding: toRem(18) 0;
    font-size: toRem(17);
    display: flex;
    justify-content: center;
    gap: toRem(10);
    .photo-icon {
      font-size: toRem(25);
    }
    .cam-icon {
      color: $secondary-color;
    }
    .check-icon {
      color: green;
    }
  }
  &__go-back-btn {
    width: 100%;
    font-size: toRem(17);
    padding: toRem(18) 0;
    .arrow-icon-left {
      margin-right: toRem(20);
      opacity: 0.5;
    }
  }
}
.proceed {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__button {
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 toRem(20) toRem(20) rgb(218, 218, 218);
    border-radius: 50%;
    padding: toRem(10);
    &--btn {
      display: inline-block;
      width: toRem(100);
      height: toRem(100);
      position: relative;
      .arrow-icon-right {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: toRem(40);
      }
    }
  }
  &__subtitle {
    margin-top: toRem(20);
  }
}
</style>
