<template>
  <div class="container" ref="container">
    <div class="subject">
      <p class="subject__name">
        {{ subject.subject.name }}
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="x-icon"
          @click="deleteSubject(subject.id)"
        />
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  props: {
    subject: {
      type: Object,
    },
  },
  methods: {
    async deleteSubject(id) {
      try {
        await api({ requiresAuth: true })
          .delete(`/school-schedule/${id}/delete`)
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit('daySchedule/SET_DELETE_SUBJECT', id)
            }
          })
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.subject {
  height: toRem(100);
  background-color: #f8f7eb;
  border-radius: toRem(5);
  box-shadow: 0 toRem(2) toRem(5) rgb(177, 177, 177);
  position: relative;
  &__name {
    padding: toRem(20) 0 0 toRem(20);
    font-weight: 500;
    font-size: toRem(18);
  }
  .x-icon {
    position: absolute;
    top: toRem(10);
    right: toRem(10);
  }
}
</style>
