<template>
  <div class="container">
    <div class="add-child__button">
      <img src="../assets/images/female.png" class="user-icon" />
      <img src="../assets/images/male.png" class="user-icon user-icon--boy" />
      <router-link to="/child" class="btn btn--add-child"
        ><font-awesome-icon icon="fa-solid fa-plus" class="plus-icon"
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.add-child__button {
  background: rgba(255, 255, 255, 0.21);
  backdrop-filter: blur(0);
  border-radius: 100%;
  padding: toRem(10) toRem(10);
  position: relative;
  box-shadow: 0 toRem(20) toRem(20) rgb(218, 218, 218);
  .user-icon {
    width: 50%;
    position: absolute;
    left: 80%;
    top: 30%;
    &--boy {
      left: -30%;
    }
  }
}
.btn--add-child {
  width: toRem(160);
  height: toRem(160);
  border-radius: 100%;
  display: block;
  position: relative;
  box-shadow: inset toRem(20) toRem(-14) rgb(92, 69, 163);
  .plus-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: toRem(80);
  }
}
</style>
