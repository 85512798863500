<template>
  <div class="container">
    <div class="logo">
      <img src="../assets/icons/neat-logo.png" alt="logo" class="logo__img" />
      <p class="logo__title">NEAT</p>
    </div>
    <div class="forgot">
      <form class="forgot__form" @submit.prevent="onSubmit">
        <h2 class="forgot__title">Forgot Password</h2>
        <p class="forgot__paragraph">
          Please enter the email associated with your account and we'll send an
          email with instructions to reset your password.
        </p>
        <div class="forgot__group">
          <label for="email" class="forgot__label">{{ emailError }}</label>
          <input
            v-model="email"
            type="email"
            name="email"
            id="email"
            class="input forgot__email"
            :class="{ 'red-border': emailError }"
            placeholder="Email"
          />
          <p class="message text-success">
            {{ emailSuccsess }}
          </p>
        </div>
        <button class="btn forgot__button" tybe="submit">Submit</button>
        <router-link to="/login" class="forgot__link"
          >Go back to Login</router-link
        >
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      email: '',
      emailError: null,
      emailSuccsess: null,
    }
  },
  methods: {
    async onSubmit() {
      if (!this.email) this.emailError = 'Email should not be empty'
      else this.emailError = null
      if (this.email) {
        try {
          const response = await this.forgotPassword({ email: this.email })
          if (response.status == 201) {
            this.emailSuccsess = 'Sent successfully'
          }
        } catch (err) {
          if (err.response.status === 404) {
            this.emailError = 'User with that email not found!'
          }
        }
      }
    },
    ...mapActions(['forgotPassword']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_inputs.scss';
.container {
  width: 100%;
  height: 100%;
  background: $primary-color;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: toRem(60);
  &__img {
    width: toRem(80);
    object-fit: cover;
  }
  &__title {
    text-align: center;
    font-size: toRem(30);
    letter-spacing: toRem(7);
    margin-top: toRem(10);
    font-weight: 500;
  }
}
.forgot__image {
  width: toRem(80);
  object-fit: cover;
}
.forgot__form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.forgot__title {
  text-align: center;
  padding: toRem(20) 0;
}
.forgot__paragraph {
  text-align: center;
  margin-bottom: toRem(30);
  font-size: toRem(13);
  font-weight: 500;
}
.forgot__group {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: toRem(20);
}
.forgot__label {
  position: absolute;
  font-size: toRem(10);
  left: toRem(20);
  top: toRem(4);
  color: red;
}
.forgot__email {
  padding: toRem(18) toRem(20);
}
.forgot__button {
  width: 100%;
  padding: toRem(18) toRem(20);
  margin-bottom: toRem(20);
  margin-top: toRem(10);
}
.forgot__link {
  font-size: toRem(13);
  display: flex;
  justify-content: center;
}
.message {
  font-size: toRem(13);
  position: absolute;
  bottom: 0;
  left: toRem(15);
}
.text-success {
  color: green;
  font-weight: 700;
}
.red-border {
  border: toRem(1) solid red;
}
</style>
