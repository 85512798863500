<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="container">
      <h2 class="title">Add Parent</h2>
      <form class="form" @submit.prevent="onSubmit">
        <div class="email">
          <label
            for="email"
            class="email__label"
            :class="{ 'text-success': emailSuccsess }"
          >
            {{ emailError || emailSuccsess }}</label
          >
          <input
            type="email"
            name="email"
            id="email"
            v-model="email"
            class="input add-parent add-parent__email"
            :class="{ 'red-border': emailError, 'green-border': emailSuccsess }"
            placeholder="Email"
          />
        </div>
        <button class="btn add-parent add-parent__button" type="submit">
          Send invitation link
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      email: null,
      emailError: null,
      emailSuccsess: null,
      secondParent: [],
    }
  },
  methods: {
    async onSubmit() {
      if (!this.email) this.emailError = 'Email should not be empty'
      else this.emailError = null
      if (this.email) {
        try {
          const response = await this.inviteParent({ email: this.email })
          if (response.status == 201) {
            this.emailSuccsess = 'Sent successfully'
          }
        } catch (err) {
          console.error(err.response.data.message)
        }
      }
    },
    ...mapActions(['inviteParent']),
  },
  components: {
    HeaderComponent,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_inputs.scss';
.title {
  text-align: center;
  margin: toRem(80) 0 toRem(40) 0;
}
.form {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: toRem(20) auto;
}
.add-parent {
  width: 100%;
  box-sizing: border-box;
  padding: toRem(15) 0 toRem(15) toRem(20);
  &__button {
    margin-top: toRem(20);
  }
}
.email {
  position: relative;
  &__label {
    position: absolute;
    font-size: toRem(10);
    left: toRem(20);
    top: toRem(2);
    color: red;
  }
}
.red-border {
  border: toRem(1) solid red;
}
.green-border {
  border: toRem(1) solid green;
}
.text-success {
  color: green;
  font-weight: 700;
}
</style>
