import { render, staticRenderFns } from "./SetReminder.vue?vue&type=template&id=301fe16a&scoped=true&"
import script from "./SetReminder.vue?vue&type=script&lang=js&"
export * from "./SetReminder.vue?vue&type=script&lang=js&"
import style0 from "./SetReminder.vue?vue&type=style&index=0&id=301fe16a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301fe16a",
  null
  
)

export default component.exports