<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="container">
      <div class="week">
        <div class="week__title">
          <div class="week__image">
            <img src="../assets/images/profile-img.png" />
          </div>
          <h3 class="week__name">Week Schedule<br /><span>Daniel</span></h3>
        </div>
        <div class="week__days">
          <router-link to="/current-day"
            ><button class="week__day school">monday</button></router-link
          >
          <router-link to="#"
            ><button class="week__day school-activity">
              tuesday
            </button></router-link
          >
          <router-link to="#"
            ><button class="week__day school">wednesday</button></router-link
          >
          <router-link to="#"
            ><button class="week__day">thursday</button></router-link
          >
          <router-link to="#"
            ><button class="week__day">friday</button></router-link
          >
          <router-link to="#"
            ><button class="week__day">saturday</button></router-link
          >
          <router-link to="#" class="last-item"
            ><button class="week__day">sunday</button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/global/HeaderComponent.vue'
export default {
  data() {
    return {}
  },
  components: {
    HeaderComponent,
  },
}
</script>

<style lang="scss" scoped>
.week {
  display: flex;
  flex-direction: column;
  gap: toRem(40);
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: toRem(10);
  }
  &__image {
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 toRem(5) toRem(10) rgb(88, 88, 88);
    padding: toRem(8) toRem(8) 0 toRem(8);
    border-radius: 50%;
    img {
      width: toRem(80);
      height: toRem(80);
      border-radius: 100%;
    }
  }
  &__name {
    font-weight: 600;
    span {
      font-size: toRem(25);
    }
  }
  &__days {
    display: grid;
    grid-template-columns: repeat(3, toRem(90));
    grid-gap: toRem(20);
    justify-content: center;
  }
  &__day {
    background-color: #e6fac6;
    color: #90ce2d;
    width: toRem(90);
    height: toRem(90);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: toRem(10);
    border-radius: 100%;
    border: toRem(3) solid white;
    box-shadow: 0 toRem(2) toRem(10) rgb(128, 128, 128);
  }
  .last-item {
    grid-column: 1 / span 3;
    margin: 0 auto;
  }
  .school {
    background: $yellow-color;
    color: $black-color;
  }
  .activity {
    background: $light-blue-color;
    color: $black-color;
  }
  .school-activity {
    background: linear-gradient(
      -45deg,
      $light-blue-color 50%,
      $yellow-color 50%
    );
    color: $black-color;
  }
}
</style>
