<template>
  <div class="signup-wrapper">
    <div class="logo">
      <img class="logo__img" src="../assets/icons/neat-logo.png" alt="" />
      <p class="logo__title">NEAT</p>
    </div>
    <div class="signup">
      <h2 class="signup__title">Sign Up</h2>
      <form class="signup__form" @submit.prevent="onSubmit">
        <div class="signup__block name">
          <label for="name" class="signup__label">{{ errors.fullname }}</label>
          <input
            type="text"
            name="fullname"
            id="fullname"
            v-model="userData.fullname"
            placeholder="Full Name"
            class="input signup__item"
            :class="{ 'red-border': errors.fullname }"
          />
        </div>
        <div class="signup__block email">
          <label for="name" class="signup__label">{{ errors.email }}</label>
          <input
            type="email"
            name="email"
            id="email"
            v-model="userData.email"
            placeholder="Email"
            class="input signup__item"
            :class="{ 'red-border': errors.email }"
          />
        </div>
        <div class="signup__block username">
          <label for="name" class="signup__label"> {{ errors.username }}</label>
          <input
            type="text"
            name="username"
            id="username"
            v-model="userData.username"
            placeholder="Username"
            class="input signup__item"
            :class="{ 'red-border': errors.username }"
          />
        </div>
        <div class="signup__block password">
          <label for="name" class="signup__label"> {{ errors.password }}</label>
          <input
            type="password"
            name="password"
            id="password"
            v-model="userData.password"
            placeholder="Password"
            class="input signup__item"
            :class="{ 'red-border': errors.password }"
          />
        </div>
        <div class="signup__block confirm-password">
          <label for="name" class="signup__label">{{
            errors.confirm_password
          }}</label>
          <input
            type="password"
            name="confirm_password"
            id="confirm_password"
            v-model="userData.confirm_password"
            placeholder="Confirm Password"
            class="input signup__item"
            :class="{ 'red-border': errors.confirm_password }"
          />
        </div>
        <button
          type="submit"
          value="Sign Up"
          class="btn signup__item btn-signup"
        >
          Sign Up
        </button>
        <router-link to="/login" class="signup__link"
          >Already have an account? Log in</router-link
        >
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userData: {
        fullname: '',
        email: '',
        username: '',
        password: '',
        confirm_password: '',
      },
      errors: {
        fullname: null,
        email: null,
        username: null,
        password: null,
        confirm_password: null,
      },
    }
  },
  methods: {
    async onSubmit() {
      this.errors.fullname = this.userData.fullname ? '' : 'Full Name Required'
      this.errors.email = this.userData.email ? '' : 'Email Required'
      this.errors.username = this.userData.username ? '' : 'Username Required'
      this.errors.password = this.userData.password ? '' : 'Password Required'
      this.errors.confirm_password = this.userData.confirm_password
        ? ''
        : 'Confirm password Required'
      if (Object.values(this.userData).every((value) => value)) {
        try {
          const {
            data: { user, access_token },
          } = await this.$store.dispatch('signup', {
            full_name: this.userData.fullname,
            email: this.userData.email,

            username: this.userData.username,

            password: this.userData.password,

            password_confirm: this.userData.confirm_password,
            token: this.$route.params.token,
          })
          this.$store.commit('SET_USER_DATA', user)

          localStorage.setItem('access_token', access_token)

          this.$router.push({ name: 'AddChild' })
        } catch (err) {
          let errorArray = []

          if (err.response.status === 400) {
            errorArray = [...err.response.data.message]

            errorArray.forEach((error) => {
              if (error.includes('email')) {
                this.errors.email = 'That email is taken'
              } else if (error.includes('username is taken')) {
                this.errors.username = 'That username is taken'
              } else if (error.includes('username must be longer')) {
                this.errors.username =
                  'Username must be longer than or equal to 6 characters'
              } else if (error.includes('password')) {
                this.errors.password =
                  'Password must be longer than or equal to 6 characters'
              } else if (error.includes("doesn't match")) {
                this.errors.confirm_password = "Password doesn't match"
              } else {
                this.errors.username = ''
                this.errors.password = ''
                this.errors.confirm_password = ''
              }
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_inputs.scss';
.signup-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $primary-color;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__img {
    width: toRem(80);
    object-fit: cover;
  }
  &__title {
    text-align: center;
    font-size: toRem(20);
    letter-spacing: toRem(7);
    margin-top: toRem(10);
    font-weight: 500;
  }
}
.signup {
  overflow: auto;
  &__form {
    display: flex;
    flex-direction: column;
    gap: toRem(30);
    margin-top: toRem(25);
    padding: 0 toRem(50);

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }
  &__title {
    text-align: center;
    margin-top: toRem(30);
  }
  &__item {
    padding: toRem(18) toRem(10) toRem(18) toRem(20);
  }
  &__link {
    font-size: toRem(13);
    text-align: center;
    margin-bottom: toRem(20);
  }
  &__block {
    position: relative;
  }
  &__label {
    position: absolute;
    font-size: toRem(10);
    left: toRem(20);
    top: toRem(4);
    color: red;
  }
  .red-border {
    border: toRem(1) solid red;
  }
}
</style>
